import React, { useState } from "react";
import Cookies from "js-cookie";

import { IconButton, Icon, Tooltip, Dialog } from "@mui/material";
import Grid from "@mui/material/Grid2";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RestoreIcon from "@mui/icons-material/Restore";
import { DarkMode, LightMode } from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { setTransparentSidenav, setWhiteSidenav, setDarkMode } from "context";
import { useMaterialUIController } from "context";
import { useZoom } from "context/zoomContext";

export default function SettingsRibbon() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const { zoom, setZoom } = useZoom();

  const [isOpen, setIsOpen] = useState(false);

  const handleDarkMode = () => {
    // Set Dark / Light Sidenav
    if (darkMode) {
      // white Sidenav
      Cookies.set("darkMode", "false", { secure: true, sameSite: "strict" });
      setWhiteSidenav(dispatch, false);
      setTransparentSidenav(dispatch, false);
    } else {
      // dark Sidenav
      Cookies.set("darkMode", "true", { secure: true, sameSite: "strict" });
      setWhiteSidenav(dispatch, false);
      setTransparentSidenav(dispatch, false);
    }
    // Toggle Dark / Light Theme
    setDarkMode(dispatch, !darkMode);
  };

  const toggleSettings = () => {
    setIsOpen(!isOpen);
  };

  const increaseZoom = () => {
    if (zoom < 1) {
      setZoom(zoom + 0.1);
    }
  };

  const decreaseZoom = () => {
    if (zoom > 0.6) {
      setZoom(zoom - 0.1);
    }
  };

  const resetZoom = () => {
    setZoom(1);
  };

  return (
    <>
      {/* Settings Dialog */}
      <Dialog open={isOpen} onClose={toggleSettings}>
        <MDBox
          bgColor={darkMode ? "primary" : "white"}
          zIndex={3999}
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: "250px",
            height: "auto",
            borderTopLeftRadius: "12px",
            padding: "1rem",
          }}
        >
          <Grid container spacing={2}>
            {/* Close Button */}
            <Grid size={12}>
              <IconButton
                sx={{
                  position: "absolute",
                  top: "0.8rem",
                  right: "0.8rem",
                  cursor: "pointer",
                }}
                onClick={toggleSettings}
              >
                <Icon>close</Icon>
              </IconButton>
            </Grid>
            {/* Theme Toggle */}
            <Grid size={12} sx={{ mt: 4 }}>
              <MDTypography variant="h6">Theme Settings</MDTypography>
            </Grid>
            <Grid size={12} sx={{ ml: 2 }}>
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Grid size={6}>
                  <MDTypography variant="h6" fontSize="14px" fontWeight="regular">
                    {`Light / Dark`}
                  </MDTypography>
                </Grid>
                <Grid size={6}>
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="1.7rem"
                    height="1.7rem"
                    bgColor={darkMode ? "white" : "dark"}
                    borderRadius="50%"
                    color={darkMode ? "black" : "white"}
                    sx={{ cursor: "pointer" }}
                    onClick={handleDarkMode}
                  >
                    {darkMode ? (
                      <Tooltip arrow placement="top" title="Toggle to Light Mode">
                        <IconButton color="inherit">
                          <LightMode sx={{ width: "16px !important", height: "16px !important" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip arrow placement="top" title="Toggle to Dark Mode">
                        <IconButton color="inherit">
                          <DarkMode sx={{ width: "16px !important", height: "16px !important" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            {/* Zoom Controls */}
            <Grid size={12}>
              <MDTypography variant="h6">Zoom Settings</MDTypography>
              <Grid size={12} sx={{ ml: 1 }}>
                <Tooltip arrow placement="top" title="Zoom-in">
                  <IconButton onClick={increaseZoom}>
                    <ZoomInIcon
                      color={darkMode ? "white" : "dark"}
                      sx={{ width: "28px !important", height: "28px !important" }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Zoom-out">
                  <IconButton onClick={decreaseZoom}>
                    <ZoomOutIcon
                      color={darkMode ? "white" : "dark"}
                      sx={{ width: "28px !important", height: "28px !important" }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Reset Zoom">
                  <IconButton onClick={resetZoom}>
                    <RestoreIcon color={darkMode ? "white" : "dark"} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Dialog>
      {/* Floating Settings Button */}
      <MDBox
        display={isOpen ? "none" : "flex"}
        justifyContent="center"
        alignItems="center"
        width="2.5rem"
        height="2.5rem"
        bgColor={darkMode ? "white" : "primary"}
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="1.5rem"
        bottom="1.5rem"
        zIndex={3999}
        color={darkMode ? "black" : "white"}
        sx={{ cursor: "pointer" }}
        onClick={toggleSettings}
      >
        <Icon fontSize="small" color="inherit">
          settings
        </Icon>
      </MDBox>
    </>
  );
}
