// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { grey } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

const tabs = {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: grey[100],
      borderRadius: borderRadius.xl,
      minHeight: "unset",
      padding: pxToRem(4),
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    indicator: {
      opacity: 0.9,
      height: "100%",
      borderRadius: borderRadius.md,
      // backgroundColor: `${secondary.main} !important`,
      boxShadow: tabsBoxShadow.indicator,
      transition: "all 500ms ease",
      // borderBottom: "2px solid",
      // borderBottomColor: "transparent",
      // borderBottomLeftRadius: 0,
      // borderBottomRightRadius: 0,
    },
  },
};

export default tabs;
