import React, { useState, useEffect, useContext, createContext } from "react";
import { jwtDecode } from "jwt-decode";
import PropTypes from "prop-types";
import { configureApiClient } from "sharedServices/apiClient";
import axios from "axios";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reloadToken, setReloadToken] = useState(true);

  const reloadAccessToken = () => {
    setReloadToken(true);
  };

  // Configure the Axios instance with the context methods
  const apiClient = configureApiClient({ reloadAccessToken });

  const isTokenValid = (token = accessToken) => {
    try {
      const currentTime = Date.now() / 1000; // Current time in seconds since Unix Epoch
      if (typeof token === "string") {
        const decoded = jwtDecode(token);
        const tokenExpired = decoded.exp ? decoded.exp < currentTime : true;
        if (!tokenExpired) {
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      // Handle error if the token is not valid JSON Web Token
      console.error(error.message);
      return false;
    }
  };

  const getUserAndToken = () => {
    try {
      const token = localStorage.getItem("access_token");
      if (isTokenValid(token)) {
        return { tokenData: jwtDecode(token), apiAccessToken: token };
      }
      return { tokenData: null, apiAccessToken: null };
    } catch (error) {
      // Handle error if the token is not valid JSON Web Token
      console.error(error.message);
      return { tokenData: null, apiAccessToken: null };
    }
  };

  const logout = async () => {
    localStorage.removeItem("userPerms");
    localStorage.removeItem("access_token");
    Cookies.remove("mfaSessionId");
    await axios.get(`${process.env.REACT_APP_API}/auth/logout`, {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });
    window.location.href = process.env.REACT_APP_LOGIN_PAGE;
  };

  useEffect(() => {
    const processTokens = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const { tokenData, apiAccessToken } = getUserAndToken();

        if (!tokenData || !apiAccessToken) {
          // only check for refresh token if accessToken is not null
          if (token) {
            // Try to refresh the token
            const res = await axios.post(
              `${process.env.REACT_APP_API}/auth/refresh-token`,
              {},
              {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
              }
            );
            if (res.data.isSuccessful) {
              localStorage.setItem("access_token", res.data.accessToken);
              setUser(jwtDecode(res.data.accessToken));
              setAccessToken(res.data.accessToken);
              window.location.reload();
            } else {
              throw new Error(res.data.message);
            }
          }
        } else {
          setUser(tokenData);
          setAccessToken(apiAccessToken);
        }
      } catch (error) {
        await logout();
        console.error(error);
      } finally {
        setLoading(false);
        setReloadToken(false);
      }
    };

    if (reloadToken) {
      processTokens();
    }
  }, [reloadToken]);

  return (
    <AuthContext.Provider value={{ user, accessToken, loading, apiClient, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
