import React, { Suspense } from "react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React components
import CustomLoading from "components/CustomComponents/CustomLoading";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// protected routes that should be included in the side navbar
import protectedRoutes from "routes";
// Other protected routes that should not be included in the side navbar
import { otherProtectedRoutes } from "routes";
// public routes that can be accessed without authentication
import { publicRoutes } from "routes";
// public routes for authentication like login page
import { authRoutes } from "routes";

import SettingsRibbon from "components/CustomComponents/SettingsRibbon";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";
import { setTransparentSidenav, setWhiteSidenav, setDarkMode } from "context";
import { useAuth } from "context/authProviderContext";
import { useSnackbar } from "context/snackbarContext";
// Images
import brandLogo from "assets/images/brand/brand_logo_white.webp";

const renderLoader = () => <CustomLoading />;

export default function App() {
  const { loading, accessToken, apiClient } = useAuth();
  const { errorAlert } = useSnackbar();
  const [controller, dispatch] = useMaterialUIController();
  const [userHomepage, setUserHomepage] = useState(process.env.REACT_APP_HOMEPAGE);
  const [userPerms, setUserPerms] = useState([]);
  const [loadingUserPerms, setLoadingUserPerms] = useState(true);
  // const [mfaSessionId, setMFASessionId] = useState(null);
  // const [isMFASessionIdLoading, setMFASessionIdLoading] = useState(true);
  // const [otp, setOTP] = useState("");
  // const [secret, setSecret] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  // const [isMFAActive, setIsMFAActive] = useState(false);
  // const [isMFAForced, setIsMFAForced] = useState(false);
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const brandName = process.env.REACT_APP_NAME;

  // const handleDarkMode = () => {
  //   // Set Dark / Light Sidenav
  //   if (darkMode) {
  //     // white Sidenav
  //     Cookies.set("darkMode", "false", { secure: true, sameSite: "strict" });
  //     setWhiteSidenav(dispatch, false);
  //     setTransparentSidenav(dispatch, false);
  //   } else {
  //     // dark Sidenav
  //     Cookies.set("darkMode", "true", { secure: true, sameSite: "strict" });
  //     setWhiteSidenav(dispatch, false);
  //     setTransparentSidenav(dispatch, false);
  //   }
  //   // Toggle Dark / Light Theme
  //   setDarkMode(dispatch, !darkMode);
  // };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    const setTheme = () => {
      if (Cookies.get("darkMode") === "true" && !darkMode) {
        // set darkMode to true
        setWhiteSidenav(dispatch, false);
        setTransparentSidenav(dispatch, false);
        setDarkMode(dispatch, !darkMode);
      } else if (Cookies.get("darkMode") === "false" && darkMode) {
        // set darkMode to false
        setWhiteSidenav(dispatch, true);
        setDarkMode(dispatch, !darkMode);
      }
    };

    const setTitle = () => {
      try {
        const route = pathname.split("/").slice(1);
        let titlePart2 = route
          .slice(-1)[0]
          .split("-")
          .map((s) => s[0].toUpperCase() + s.substring(1));
        document.title = `${titlePart2.join(" ")} - ${brandName}`;
      } catch (error) {
        document.title = brandName;
      }
    };

    setTitle();
    setTheme();

    // Set sidenav color based on client ID in the URL pathname
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    let mounted = true;
    if (mounted && !loading) {
      if (!accessToken) {
        // if access_token is not present and the page is not login page
        const route = pathname.split("/").slice(1);
        localStorage.removeItem("access_token");
        if (!route.includes("login")) {
          navigate("/login");
        }
      }
    }
    return () => (mounted = false);
  }, [loading]);

  useEffect(() => {
    const getUserPermissions = async () => {
      if (accessToken) {
        try {
          const res = await apiClient.get(`/me/permissions`, {
            headers: { authorization: "Bearer " + accessToken },
          });
          setUserPerms(res.data.rows ?? ["no_permissions"]);
          if (res.data.rows) {
            localStorage.setItem("userPerms", res.data.rows.toString());
          }
        } catch (error) {
          console.error(error);
          errorAlert(error);
        } finally {
          setLoadingUserPerms(false);
        }
      } else {
        setUserPerms([]);
        setLoadingUserPerms(false);
      }
    };

    if (!loading && loadingUserPerms) {
      const perms = localStorage.getItem("userPerms");
      if (typeof perms !== "string") {
        getUserPermissions();
      } else if (userPerms.length === 0) {
        setUserPerms(perms.split(",") ?? ["no_permissions"]);
        setLoadingUserPerms(false);
      }
    }
  }, [loading, loadingUserPerms]);

  const getAuthorizedRoutes = (allRoutes, userPermissions) => {
    return allRoutes.map((route) => {
      if (route.collapse) {
        return getAuthorizedRoutes(route.collapse, userPermissions);
      } else if (
        userPermissions != null &&
        Array.isArray(userPermissions) &&
        route.route &&
        route.permissions
      ) {
        const allowAccess = route.permissions.every((p) => userPermissions.includes(p));
        if (allowAccess) {
          if (userHomepage === process.env.REACT_APP_HOMEPAGE) {
            setUserHomepage(route.route);
          }
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        } else return null;
      } else if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      } else return null;
    });
  };

  const getGeneralRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getGeneralRoutes(route.collapse);
      } else if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      } else return null;
    });

  const getUserHomepage = () => {
    let homepage = process.env.REACT_APP_HOMEPAGE;
    return homepage;
  };

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3rem"
  //     height="3rem"
  //     bgColor={darkMode ? "white" : "dark"}
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color={darkMode ? "black" : "white"}
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleDarkMode}
  //   >
  //     {darkMode ? (
  //       <Icon fontSize="medium" color="inherit">
  //         light_mode
  //       </Icon>
  //     ) : (
  //       <Icon fontSize="medium" color="inherit">
  //         dark_mode
  //       </Icon>
  //     )}
  //   </MDBox>
  // );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {loading || loadingUserPerms ? (
        <CustomLoading />
      ) : (
        <>
          {layout === "dashboard" && accessToken && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brandLogo}
                brandName={brandName}
                routes={protectedRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* <Configurator /> */}
              <SettingsRibbon />
              {/* {configsButton} */}
            </>
          )}
          <Suspense fallback={renderLoader()}>
            <Routes>
              {accessToken ? (
                <>
                  {getGeneralRoutes(publicRoutes)}
                  {getAuthorizedRoutes(otherProtectedRoutes, userPerms ?? [])}
                  {getAuthorizedRoutes(protectedRoutes, userPerms ?? [])}
                  <Route exact path="/" element={<Navigate to={getUserHomepage()} />} />
                  <Route path="/*" element={<Navigate to={`/page-not-found`} />} />
                </>
              ) : (
                <>
                  {getGeneralRoutes(authRoutes)}
                  <Route path="/*" element={<Navigate to={`/login`} />} />
                </>
              )}
            </Routes>
          </Suspense>
        </>
      )}
    </ThemeProvider>
  );
}
